import { Model } from 'miragejs';

const models = {
  feedbackDetail: Model,
  transactionHistory: Model,
  mistakeCause: Model,
  pendingDetail: Model,
  queuesTransaction: Model,
};

export default models;
