import MessageMap from '../i18n.d';

const ptBR: MessageMap = {
  DASHBOARD_TITLE: 'BECKS Feedback',
  FEEDBACK_TRANSACTION_DETAILS: {
    TITLE: 'Detalhes da transação de feedback',
    TOOLTIP_MESSAGE: 'Resumo dos status da fila de feedback',
    LEGEND_SUCCESS: 'Sucesso',
    LEGEND_PENDING: 'Pendente',
    LEGEND_FAILED: 'Erro',
  },
  HOW_LONG_IS_PENDING: {
    TITLE: 'Quanto tempo está pendente?',
    TOOLTIP_MESSAGE: 'Quantidade de transações pendentes na fila de feedback (em dias)',
    SECTION_PERIOD: {
      TYPE_ONE: 'Dia',
      TYPE_TWO: 'Dias',
      TYPE_THREE: 'Semanas',
    },
    TYPE_VALUE: 'Quantidade',
  },
  WHAT_ARE_THE_MISTAKES: {
    TITLE: 'Quais são os erros?',
    TOOLTIP_MESSAGE: 'Resumo dos tipos de erros na fila de feedback',
    LEGEND_SUCCESS: 'Sucesso',
    LEGEND_PENDING: 'Pendente',
    LEGEND_FAILED: 'Falho',
  },
  TRANSACTION_HISTORY: {
    TITLE: 'Histórico de transações',
    TOOLTIP_MESSAGE: 'Histórico das transações de feedback dos últimos 7 dias',
    LEGEND_SUCCESS: 'Sucesso',
    LEGEND_PENDING: 'Pendente',
    LEGEND_ERROR: 'Erro',
  },
  DETAILED_QUEUES: {
    TITLE: 'Filas detalhadas',
    TOOLTIP_MESSAGE:
      'Descrição de cada fila das integrações com seus respectivos payloads da transação',
    SECTION_FEEDBACK: 'Feedback',
    SECTION_BUSINESS: 'Business',
    SECTION_ETL: 'ETL',
    SECTION_BFI: 'BFI',
    TABLE_TIMESTAMP: 'Carimbo de data/hora',
    TABLE_PAYLOAD: 'Carga útil',
    PAYLOAD_TYPE: {
      TYPE_ONE: 'Dono',
    },
    EMPTY_MESSAGE: 'Não há dados para consulta nessa fila',
  },
};

export default ptBR;
