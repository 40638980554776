import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Spinner } from 'supplier-portal-shared-components';

const DashboardPage = lazy(() => import('./pages/Dashboard/DashboardPage'));

const Routes = (): JSX.Element => (
  <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
    <Switch>
      <Route path="/saastools-becks" exact component={DashboardPage} />
    </Switch>
  </Suspense>
);
export default Routes;
