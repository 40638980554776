import { Server } from 'miragejs';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function seeds(server: Server) {
  createFeedbackDetail(server);
}

function createFeedbackDetail(server: Server) {
  server.create('feedbackDetail');
  server.create('transactionHistory');
  server.create('mistakeCause');
  server.create('pendingDetail');
  server.create('queuesTransaction');
}
