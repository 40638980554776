/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const feedbackDetailsRoutes = (context) => {
  context.get('/feedbackDetail', (schema) => {
    const feedbackDetails = schema.feedbackDetails.all().models;
    return { ...feedbackDetails };
  });
};

export const transactionHistoryRoutes = (context) => {
  context.get('/transactionHistory', (schema) => {
    const transactionHistory = schema.transactionHistories.all().models;
    return { ...transactionHistory };
  });
};

export const mistakeCauseRoutes = (context) => {
  context.get('/mistakeCause', (schema) => {
    const mistakeCause = schema.mistakeCauses.all().models;
    return { ...mistakeCause };
  });
};

export const pendingDetailsRoutes = (context) => {
  context.get('/pendingDetail', (schema) => {
    const pendingDetails = schema.pendingDetails.all().models;
    return { ...pendingDetails };
  });
};

export const queuesTransactionRoutes = (context) => {
  context.get('/queuesTransaction/:queue', (schema, request) => {
    const { queue } = request.params;
    const queuesTransactions = schema.queuesTransactions.all(queue).models[0].attrs[queue];
    return { ...queuesTransactions };
  });
};
