import { Factory } from 'miragejs';
import bfiMock from './mocks/bfiMock.json';
import businessMock from './mocks/businessMock.json';
import etlMock from './mocks/etlMock.json';
import feedbackMock from './mocks/feedbackMock.json';

export const factories = {
  feedbackDetail: Factory.extend({
    graphicPercents: {
      success: 30,
      pending: 50,
      error: 20,
    },
  }),
  transactionHistory: Factory.extend({
    graphicLines: [
      {
        name: '05/05/2022',
        pending: 4000,
        success: 2400,
        error: 1000,
      },
      {
        name: '06/05/2022',
        pending: 3000,
        success: 1398,
        error: 1000,
      },
      {
        name: '07/05/2022',
        pending: 2000,
        success: 9800,
        error: 1000,
      },
      {
        name: '08/05/2022',
        pending: 2780,
        success: 3908,
        error: 1000,
      },
      {
        name: '09/05/2022',
        pending: 1890,
        success: 4800,
        error: 1000,
      },
      {
        name: '10/05/2022',
        pending: 2390,
        success: 3800,
        error: 1000,
      },
      {
        name: '11/05/2022',
        pending: 3490,
        success: 4300,
        error: 1000,
      },
    ],
  }),
  mistakeCause: Factory.extend({
    chartPercents: {
      success: 30,
      pending: 50,
      error: 20,
    },
  }),
  pendingDetail: Factory.extend({
    graphicNumbers: [
      {
        period: '1',
        value: 10,
      },
      {
        period: '2',
        value: 20,
      },
      {
        period: '3',
        value: 30,
      },
      {
        period: '4',
        value: 40,
      },
      {
        period: '5',
        value: 50,
      },
      {
        period: '6',
        value: 60,
      },
    ],
  }),
  queuesTransaction: Factory.extend({
    feedback: { data: feedbackMock },
    bfi: { data: bfiMock },
    business: { data: businessMock },
    etl: { data: etlMock },
  }),
};

export default factories;
