import { RestSerializer } from 'miragejs';

const serializers = {
  feedbackDetail: RestSerializer.extend({ embed: true }),
  transactionHistory: RestSerializer.extend({ embed: true }),
  mistakeCause: RestSerializer.extend({ embed: true }),
  pendingDetail: RestSerializer.extend({ embed: true }),
  queuesTransaction: RestSerializer.extend({ embed: true }),
};
export default serializers;
