/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const logRoutes = (context) => {
  context.get('/log', () => ({ message: 'Information was logged' }));

  context.post('/log', () => ({ message: 'Information was updated' }));

  context.delete('/log', () => ({ message: 'Information was deleted' }));
};

export default logRoutes;
