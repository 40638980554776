import { createServer, Registry, Server } from 'miragejs';
import { AnyFactories, ModelDefinition } from 'miragejs/-types';
import factories from './factories';
import models from './models';
import logRoutes from './routes/example.js';
import {
  feedbackDetailsRoutes,
  mistakeCauseRoutes,
  pendingDetailsRoutes,
  queuesTransactionRoutes,
  transactionHistoryRoutes,
} from './routes/feedbackDetails.js';
import seeds from './seeds';
import serializers from './serializer';

type IMakeServer = Server<
  Registry<
    {
      user: ModelDefinition<Record<string, unknown>>;
      position: ModelDefinition<Record<string, unknown>>;
    },
    AnyFactories
  >
>;

const startServer = ({ environment = 'development' } = {}): IMakeServer => {
  const server = createServer({
    environment,
    models,
    factories,
    seeds,
    serializers,
    routes() {
      logRoutes(this);
      feedbackDetailsRoutes(this);
      transactionHistoryRoutes(this);
      mistakeCauseRoutes(this);
      pendingDetailsRoutes(this);
      queuesTransactionRoutes(this);
    },
  });
  server.logging = false;
  return server;
};

export default startServer;
