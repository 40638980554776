import MessageMap from '../i18n';

const esDO: MessageMap = {
  DASHBOARD_TITLE: 'BECKS Feedback',
  FEEDBACK_TRANSACTION_DETAILS: {
    TITLE: 'Detalles de la transacción de feedback',
    TOOLTIP_MESSAGE: 'Resumen del estado de la cola de comentarios',
    LEGEND_SUCCESS: 'Éxito',
    LEGEND_PENDING: 'Pendiente',
    LEGEND_FAILED: 'Error',
  },
  HOW_LONG_IS_PENDING: {
    TITLE: '¿Cuanto tiempo esta pendiente?',
    TOOLTIP_MESSAGE: 'Número de transacciones pendientes en la cola de comentarios (en días)',
    SECTION_PERIOD: {
      TYPE_ONE: 'Día',
      TYPE_TWO: 'Días',
      TYPE_THREE: 'Semanas',
    },
    TYPE_VALUE: 'Cantidad',
  },
  WHAT_ARE_THE_MISTAKES: {
    TITLE: '¿Cuales son los errores?',
    TOOLTIP_MESSAGE: 'Resumen de tipos de errores en la cola de comentarios',
    LEGEND_SUCCESS: 'Éxito',
    LEGEND_PENDING: 'Pendiente',
    LEGEND_FAILED: 'Ha fallado',
  },
  TRANSACTION_HISTORY: {
    TITLE: 'Historial de transacciones',
    TOOLTIP_MESSAGE: 'Comentarios sobre el historial de transacciones de los últimos 7 días',
    LEGEND_SUCCESS: 'Éxito',
    LEGEND_PENDING: 'Pendiente',
    LEGEND_ERROR: 'Error',
  },
  DETAILED_QUEUES: {
    TITLE: 'Colas detalladas',
    TOOLTIP_MESSAGE:
      'Descripción de cada cola de integración con sus respectivas cargas útiles de transacciones',
    SECTION_FEEDBACK: 'Feedback',
    SECTION_BUSINESS: 'Business',
    SECTION_ETL: 'ETL',
    SECTION_BFI: 'BFI',
    TABLE_TIMESTAMP: 'Marca de tiempo',
    TABLE_PAYLOAD: 'Carga útil',
    PAYLOAD_TYPE: {
      TYPE_ONE: 'Dueño',
    },
    EMPTY_MESSAGE: 'No hay datos para consultar en esta cola',
  },
};

export default esDO;
