import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
  },
  DASHBOARD_TITLE: 'BECKS Feedback',
  FEEDBACK_TRANSACTION_DETAILS: {
    TITLE: 'Feedback transaction details',
    TOOLTIP_MESSAGE: 'Feedback queue status summary',
    LEGEND_SUCCESS: 'Success',
    LEGEND_PENDING: 'Pending',
    LEGEND_FAILED: 'Error',
  },
  HOW_LONG_IS_PENDING: {
    TITLE: 'How long is pending?',
    TOOLTIP_MESSAGE: 'Number of pending transactions in the feedback queue',
    SECTION_PERIOD: {
      TYPE_ONE: 'Day',
      TYPE_TWO: 'Days',
      TYPE_THREE: 'Weeks',
    },
    TYPE_VALUE: 'Quantity',
    TYPE_PERIOD: 'Days',
  },
  WHAT_ARE_THE_MISTAKES: {
    TITLE: 'What are the mistakes?',
    TOOLTIP_MESSAGE: 'Summary of types of errors in the feedback queue',
    LEGEND_SUCCESS: 'Success',
    LEGEND_PENDING: 'Pending',
    LEGEND_FAILED: 'Failed',
  },
  TRANSACTION_HISTORY: {
    TITLE: 'Transaction History',
    TOOLTIP_MESSAGE: 'Feedback transaction history for the last seven days',
    LEGEND_SUCCESS: 'Success',
    LEGEND_PENDING: 'Pending',
    LEGEND_ERROR: 'Error',
  },
  DETAILED_QUEUES: {
    TITLE: 'Detailed Queues',
    TOOLTIP_MESSAGE:
      'Description of each integration queue with their respective transaction payloads',
    SECTION_FEEDBACK: 'Feedback',
    SECTION_BUSINESS: 'Business',
    SECTION_ETL: 'ETL',
    SECTION_BFI: 'BFI',
    TABLE_TIMESTAMP: 'Timestamp',
    TABLE_PAYLOAD: 'Payload',
    PAYLOAD_TYPE: {
      TYPE_ONE: 'Owner',
    },
    EMPTY_MESSAGE: 'There is no data to query in this queue',
  },
};

export default enUS;
